<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="8"
      md="4"
    >
      <v-form
        @submit="submitStoreId"
        v-model="valid"
        ref="storeForm"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Store Selector</v-toolbar-title>
          </v-toolbar>
          <v-card-text>

              <v-autocomplete
                placeholder="Select your store"
                :items="stores"
                :item-value="item => item.storeId"
                :item-text="item => item.storeName"
                :rules="[v => !!v || 'You must choose a store']"
                v-model="selectedStoreId"
                autofocus
              ></v-autocomplete>
              <v-checkbox
                v-model="updateDefault"
                label="Set as default store"
              ></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              type="submit"
              @click.stop.prevent="submitStoreId"
              color="primary"
              :disabled="!  valid"
            >Select Store</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'StoreSelector',
  data: function () {
    return {
      selectedStoreId: '',
      valid: false,
      updateDefault: typeof localStorage.defaultStore === 'undefined'
    }
  },
  computed: {
    ...mapState('instore', ['stores'])
  },
  methods: {
    setStoreId: function (storeId) {
      this.selectedStoreId = storeId
    },
    submitStoreId: function () {
      console.log('In SUbmit')
      if (this.$refs.storeForm.validate()) {
        // if update default, set default
        if (this.updateDefault) {
          localStorage.defaultStore = this.selectedStoreId
          this.$emit('defaultsUpdated')
        }
        this.$router.push('/' + this.selectedStoreId + '/dashboard')
      } else {
        console.log('Not valid')
      }
    }
  }
}
</script>

<style scoped>

</style>
