<template>
  <div class="store my-4">
    <StoreSelector
      @defaultsUpdated="$emit('defaultsUpdated')"
    ></StoreSelector>
  </div>
</template>

<script>
// @ is an alias to /src
import StoreSelector from '../components/StoreSelector'

export default {
  name: 'Home',
  components: { StoreSelector },
  data () {
    return {
    }
  },
  computed: {
    storeId: function () {
      return this.$route.params.storeId
    }
  }
}
</script>
